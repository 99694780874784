import React from 'react'
import Header from '../../../components/Header/Header'
import SubHeader from '../../../components/SubHeader/SubHeader'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

const OrderConfirmation = ({ type, goToProducts }) => {
    const orderNumber = useSelector((state) => state.orders.orderNumber)
    return (
        <div className="wrapper wrapper--flex">
            <Header goToProducts={goToProducts} />
            <SubHeader isBig={true} />
            <div className="products-confirm">
                <div className="products-confirm__main">
                    {type === 'order' ? (
                        <p className="products-confirm__text">
                            Thank you for ordering from Cowichan Souvenir! Your
                            order confirmation number is{' '}
                            <span className="product__blue">{orderNumber}</span>
                            .
                        </p>
                    ) : (
                        <p className="products-confirm__text">
                            Thank you for your payment!
                        </p>
                    )}
                    {type === 'order' ? (
                        <p className="products-confirm__text">
                            {' '}
                            We will contact you about any delays or issues and
                            will try to get your order out based on your
                            requested ship date.
                        </p>
                    ) : (
                        <p className="products-confirm__text">
                            {' '}
                            We have sent an email with your confirmation to your
                            payment to{' '}
                            <span className="product__blue">
                                customer@customername.com
                            </span>
                            , and for future reference, your confirmation number
                            is <span className="product__blue">#134825</span>
                        </p>
                    )}
                    {type === 'order' && (
                        <p className="products-confirm__text">
                            {' '}
                            Please contact us about any inquires regarding your
                            order.
                        </p>
                    )}
                </div>
                <div className="products-confirm__footer">
                    <NavLink to="tasks" className="link">
                        My Account
                    </NavLink>
                    <NavLink to="/history" className="link">
                        Orders
                    </NavLink>
                    <NavLink to="/contact" className="link">
                        Contact Us
                    </NavLink>
                    <NavLink to="/products" className="link">
                        Products
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default OrderConfirmation

import React, { useState, useReducer } from 'react'
import Header from '../../components/Header/Header'
import SubHeader from '../../components/SubHeader/SubHeader'
import HeaderInfo from '../../components/HeaderInfo/HeaderInfo'
import { SimpleInput } from '../../components/CustomInput/SimpleInput'
import CustomTextArea from '../../components/CustomTextArea/CustomTextArea'
import { contactUs } from '../../helpers/crud'
import { useSelector } from 'react-redux'
import './contact.scss'
import CustomButton from '../../components/CustomButton/CustomButton'
import {
    onInputChange,
    onFocusOut,
    formsReducer,
    updateFormAction,
    validateForm,
    fillFormAction,
} from '../../helpers/formUtils'
import { toast } from 'react-toastify'
import { useEffect } from 'react'

const initialState = {
    email: {
        value: '',
        touched: '',
        hasError: '',
        error: '',
    },
    message: { value: '', touched: '', hasError: '', error: '' },
    subject: { value: '', touched: '', hasError: '', error: '' },
    companyName: { value: '', touched: '', hasError: '', error: '' },
    isFormValid: false,
}

const Contact = () => {
    const [formData, setFormData] = useReducer(formsReducer, initialState)
    const user = useSelector((state) => state.auth.profileInfo)

    const handleSubmit = (e) => {
        e.preventDefault()
        const isValid = validateForm(formData, setFormData)
        if (isValid) {
            const data = {
                email: formData.email.value,
                subject: formData.subject.value,
                company_name: user?.Name,
                message: formData.message.value,
            }

            contactUs(data)
                .then((res) => {
                    setFormData(
                        fillFormAction({
                            ...formData,
                            subject: {
                                value: '',
                                touched: '',
                                hasError: '',
                                error: '',
                            },
                            message: {
                                value: '',
                                touched: '',
                                hasError: '',
                                error: '',
                            },
                        })
                    )
                    toast.success('Contact form sent successfully!')
                })
                .catch((err) => toast.error(err.response.data.message))
        }
    }

    useEffect(() => {
        if (!user) return
        setFormData(
            fillFormAction({
                ...formData,
                email: {
                    value: user.email,
                    touched: '',
                    hasError: '',
                    error: '',
                },
                companyName: {
                    value: user.Name,
                    touched: '',
                    hasError: '',
                    error: '',
                },
            })
        )
    }, [user])

    return (
        <div className="wrapper contact">
            <Header />
            <SubHeader />
            <HeaderInfo title="Contact Details" />
            <div className="contact-info">
                <p className="contact-text">
                    You can contact us by calling{' '}
                    <a className="contact__link">1-800-939-3223</a> or emailing
                    us at{' '}
                    <a className="contact__link">sales@cowichansouvenir.com</a>.
                    You can also contact us using the below email form.
                </p>
            </div>
            <form className="contact-form" onSubmit={handleSubmit}>
                <div className="contact-field">
                    <span className="contact-field__title"> Email</span>
                    <div className="df">
                        <SimpleInput
                            disabled={true}
                            value={formData.email.value}
                            name="email"
                            onChange={(e) =>
                                onInputChange(
                                    'email',
                                    e.target.value,
                                    setFormData,
                                    formData
                                )
                            }
                            onBlur={(e) =>
                                onFocusOut(
                                    'email',
                                    e.target.value,
                                    setFormData,
                                    formData
                                )
                            }
                            errorText={formData.email.error}
                            className={`${
                                formData.email.error ? 'red' : ''
                            } smallInput`}
                        />
                    </div>
                </div>
                <div className="contact-field">
                    <span className="contact-field__title">Subject</span>
                    <SimpleInput
                        value={formData.subject.value}
                        className={formData.subject.error ? 'red' : ''}
                        name="subject"
                        onChange={(e) =>
                            onInputChange(
                                'subject',
                                e.target.value,
                                setFormData,
                                formData
                            )
                        }
                        onBlur={(e) =>
                            onFocusOut(
                                'subject',
                                e.target.value,
                                setFormData,
                                formData
                            )
                        }
                        errorText={formData.subject.error}
                    />
                </div>
                <div className="contact-field">
                    <span className="contact-field__title">Company Name</span>
                    <SimpleInput
                        disabled={true}
                        value={formData.companyName.value}
                        name="companyName"
                        onChange={(e) =>
                            onInputChange(
                                'companyName',
                                e.target.value,
                                setFormData,
                                formData
                            )
                        }
                    />
                </div>

                <div className="contact-field contact-field--margin-bot">
                    <span className="contact-field__title"> Message</span>
                    <CustomTextArea
                        maxLength={1500}
                        className={formData.message.error ? 'red' : ''}
                        value={formData.message.value}
                        name="message"
                        onChange={(e) =>
                            onInputChange(
                                'message',
                                e.target.value,
                                setFormData,
                                formData
                            )
                        }
                        onBlur={(e) =>
                            onFocusOut(
                                'message',
                                e.target.value,
                                setFormData,
                                formData
                            )
                        }
                        errorText={formData.message.error}
                    />
                </div>
                <div className="contact-button">
                    <CustomButton styleButton="big" type="submit">
                        Send Email
                    </CustomButton>
                </div>
            </form>
        </div>
    )
}

export default Contact

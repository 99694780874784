import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setProducts, setQuantityError } from '../../../helpers/redux/products'
import { getProducts } from '../../../helpers/crud'
import Header from '../../../components/Header/Header'
import SubHeader from '../../../components/SubHeader/SubHeader'
import HeaderInfo from '../../../components/HeaderInfo/HeaderInfo'
import { SimpleInput } from '../../../components/CustomInput/SimpleInput'
import CustomButton from '../../../components/CustomButton/CustomButton'
import Product from '../../../components/Product/Product'
import Popup from '../../../components/Popup/Popup'
import { Loader } from '../../../components/Loader/Loader'
import { ThambnailModal } from '../../../components/Popup/ThambnailModal'
import { useRef } from 'react'

const AllProducts = ({
    hableHead,
    modal,
    modalText,
    setModal,
    goToProducts,
}) => {
    const { products, quantityError } = useSelector((state) => state.products)
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [fetching, setFetching] = useState(true)
    const [searchValue, setSearchValue] = useState('')
    const [thumbnail, setThumbnail] = useState(false)
    const [lastPage, setLastPage] = useState(10)
    const totals = useSelector((state) => state.products.totals)
    const ref = useRef()
    const dispatch = useDispatch()

    useEffect(() => {
        ref?.current?.addEventListener('scroll', scrollHandler)
        return () => ref?.current?.removeEventListener('scroll', scrollHandler)
    }, [ref])

    const scrollHandler = (e) => {
        if (
            e.target.scrollHeight -
                (e.target.scrollTop + ref.current.clientHeight) <
            100
        ) {
            setFetching(true)
        }
    }

    const loadProducts = () => {
        setLoading(true)
        getProducts(currentPage, searchValue)
            .then((res) => {
                setLastPage(res.data.meta.last_page)
                setCurrentPage((prev) => prev + 1)
                if (currentPage === 1) {
                    dispatch(setProducts(res.data.data))
                } else {
                    dispatch(setProducts([...products, ...res.data.data]))
                }
            })
            .finally(() => {
                setLoading(false)
                setFetching(false)
            })
    }

    const searchProducts = () => {
        setLoading(true)
        getProducts(1, searchValue)
            .then((res) => {
                setCurrentPage((prev) => prev + 1)
                dispatch(setProducts(res.data.data))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (!lastPage) return
        if (fetching) {
            if (currentPage > lastPage) return
            loadProducts(currentPage)
        }
    }, [fetching, lastPage])

    useEffect(() => {
        if (searchValue !== '') {
            if (searchValue.length >= 2) {
                setCurrentPage(1)
                setTimeout(() => {
                    searchProducts()
                }, 1000)
            } else {
                setCurrentPage(1)
                searchProducts()
            }
        } else {
            setCurrentPage(1)
            searchProducts()
        }
    }, [searchValue])

    const handleSearch = (e) => {
        setSearchValue(e.target.value)
    }

    return (
        <div className="wrapper">
            {loading && <Loader loading={loading} />}
            {modal ? (
                <Popup
                    message={modalText}
                    handleClose={() => setModal(false)}
                />
            ) : (
                ''
            )}
            {thumbnail ? (
                <ThambnailModal id={thumbnail} setThumbnail={setThumbnail} />
            ) : (
                ''
            )}
            {quantityError && (
                <Popup
                    handleClose={() => dispatch(setQuantityError(false))}
                    message={`Minimum quantity is not reached, please add ${quantityError} more items to save cart`}
                />
            )}
            <Header goToProducts={goToProducts} />
            <SubHeader />
            <HeaderInfo title="Your Products">
                <div className="products-head">
                    <div>
                        <span>Cart Total: </span>
                        <span className="products-head__blue">
                            ${totals?.total}
                        </span>
                    </div>
                    <div>
                        <span>Total Units: </span>
                        <span className="products-head__blue">
                            {totals?.count}
                        </span>
                    </div>
                    <div>
                        <CustomButton onClick={hableHead}>
                            Proceed to Cart
                        </CustomButton>
                    </div>
                </div>
            </HeaderInfo>
            <div className="products__search">
                <SimpleInput
                    className="search"
                    classInput="search"
                    placeholder="Search"
                    value={searchValue}
                    isSearch={true}
                    onChange={handleSearch}
                />
            </div>

            <div className="products-hero" ref={ref}>
                {products?.length
                    ? products.map((prod) => (
                          <Product
                              product={prod}
                              key={prod.id}
                              setThumbnail={setThumbnail}
                          />
                      ))
                    : 'No results'}
            </div>
        </div>
    )
}

export default AllProducts

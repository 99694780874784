import React from 'react'
import check from '../../assets/check.png'
import './checkbox.scss'
import classNames from 'classnames'

export const CustomCheckbox = ({ label, checked, onChange, styleCheckbox }) => {
    return (
        <div
            className={classNames('custom-checkbox', {
                'custom-checkbox--big': styleCheckbox === 'big',
            })}
        >
            <div className="checkbox" onClick={onChange}>
                {checked && <img src={check} alt="checked" />}
            </div>
            <div className="label pointer" onClick={onChange}>
                {label}
            </div>
        </div>
    )
}

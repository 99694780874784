import React from 'react'
import './textarea.scss'

const CustomTextArea = ({
    name,
    value,
    placeholder,
    onChange,
    maxLength = 255,
    disabled = false,
    onBlur,
    className,
    errorText,
}) => {
    return (
        <div className="custom-textarea">
            <textarea
                onBlur={onBlur}
                name={name}
                placeholder={placeholder || ''}
                value={value || ''}
                onChange={onChange}
                maxLength={maxLength}
                disabled={disabled}
                className={`custom-textarea__area ${className}`}
            ></textarea>
            {errorText && <div className="error-text">{errorText}</div>}
        </div>
    )
}

export default CustomTextArea

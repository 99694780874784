import React, { useReducer } from 'react'
import Header from '../../../components/Header/Header'
import SubHeader from '../../../components/SubHeader/SubHeader'
import HeaderInfo from '../../../components/HeaderInfo/HeaderInfo'
import { CustomCheckbox } from '../../../components/CustomCheckbox/CustomCheckbox'
import CustomButton from '../../../components/CustomButton/CustomButton'
import { ReactComponent as GoBack } from '../../../assets/back.svg'
import { SimpleInput } from '../../../components/CustomInput/SimpleInput'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { setOrderNumber } from '../../../helpers/redux/orders'
import { toast } from 'react-toastify'
import { createOrder, getCart, getCartsTotal } from '../../../helpers/crud'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
    onInputChange,
    onFocusOut,
    formsReducer,
    updateFormAction,
    validateForm,
    fillFormAction,
} from '../../../helpers/formUtils'
import { useState } from 'react'
import Popup from '../../../components/Popup/Popup'
import { setTotals } from '../../../helpers/redux/products'

const initialState = {
    company_name: { value: '', touched: '', hasError: '', error: '' },
    full_name: { value: '', touched: '', hasError: '', error: '' },
    phone_number: { value: '', touched: '', hasError: '', error: '' },
    ship_date: {
        value: new Date().setDate(new Date().getDate() + 1),
        touched: '',
        hasError: '',
        error: '',
    },
    po: { value: '', touched: '', hasError: '', error: '' },
    shipping_address: { value: '', touched: '', hasError: '', error: '' },
    notes: { value: '', touched: '', hasError: '', error: '' },
    isChecked: false,
    isFormValid: false,
}

const OrderDetails = ({ handleBack, setProductScreen, goToProducts }) => {
    const [formData, setFormData] = useReducer(formsReducer, initialState)
    const user = useSelector((state) => state.auth.profileInfo)
    const dispatch = useDispatch()
    const [errorModal, setErrorModal] = useState(false)
    const [checkedASAP, setCheckedASAP] = useState(true)
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        if (!user) return
        setFormData(
            fillFormAction({
                ...formData,
                company_name: {
                    value: user?.Name,
                    touched: '',
                    hasError: '',
                    error: '',
                },
                full_name: {
                    value: `${
                        user?.contact?.['First Name']
                            ? user?.contact?.['First Name']
                            : ''
                    } ${
                        user?.contact?.['Last Name']
                            ? user?.contact?.['Last Name']
                            : ''
                    }`,
                    touched: '',
                    hasError: '',
                    error: '',
                },
                phone_number: {
                    value: user?.['Tel 1'] ? user?.['Tel 1'] : '',
                    touched: '',
                    hasError: '',
                    error: '',
                },
                shipping_address: {
                    value:
                        user?.shipping_address?.['Is Default Address'] === 1 &&
                        `${
                            user?.shipping_address?.['Address Line 2']
                                ? user?.shipping_address?.['Address Line 2']
                                : ''
                        } ${
                            user?.shipping_address?.City
                                ? user?.shipping_address?.City
                                : ''
                        } ${
                            user?.shipping_address?.['Postal Code']
                                ? user?.shipping_address?.['Postal Code']
                                : ''
                        } ${
                            user?.shipping_address?.Country
                                ? user?.shipping_address?.Country
                                : ''
                        }`,
                    touched: '',
                    hasError: '',
                    error: '',
                },
            })
        )
    }, [user])

    const goToConfirmation = (state) => {
        const isValid = validateForm(state, setFormData)
        const date = new Date(formData.ship_date.value)
        const yyyy = date.getFullYear()
        let mm = date.getMonth() + 1 // Months start at 0!
        let dd = date.getDate()

        if (dd < 10) dd = '0' + dd
        if (mm < 10) mm = '0' + mm

        const formattedToday = yyyy + '-' + mm + '-' + dd
        const data = {
            requested_ship_date: formattedToday,
            po: state.po.value,
            company_name: state.company_name.value,
            full_name: state.full_name.value,
            phone_number: state.phone_number.value,
            shipping_address: state.shipping_address.value,
            note: state?.notes?.value,
            to_ship_asap: checkedASAP,
        }
        if (isValid) {
            createOrder(data)
                .then((res) => {
                    dispatch(setOrderNumber(res.data.data.number))
                    setProductScreen('confirmation')
                    getCartsTotal().then((res) => dispatch(setTotals(res.data)))
                })
                .catch((err) => {
                    toast.error(err.response.data.message)
                })
        }
    }

    const handleCheckbox = () =>
        setFormData(
            fillFormAction({ ...formData, isChecked: !formData.isChecked })
        )

    return (
        <div className="wrapper">
            <Header goToProducts={goToProducts} />
            {errorModal ? (
                <Popup
                    message={'You must accept the terms'}
                    handleClose={() => setErrorModal(false)}
                />
            ) : (
                ''
            )}
            <SubHeader />
            <HeaderInfo title="Order Details" />
            <div className="products__search">
                <div className="products__back" onClick={handleBack}>
                    <GoBack />
                    Return to Order Summary
                </div>
            </div>
            <div className="products-hero products-hero--size">
                <div className="products-field">
                    <span className="products-field__title"> Company Name</span>
                    <SimpleInput
                        disabled={true}
                        name="company_name"
                        value={formData.company_name.value}
                    />
                </div>
                <div className="products-field">
                    <span className="products-field__title"> Full Name</span>
                    <SimpleInput
                        disabled={true}
                        name="full_name"
                        value={formData.full_name.value}
                        onChange={(e) =>
                            onInputChange(
                                'full_name',
                                e.target.value,
                                setFormData,
                                formData
                            )
                        }
                    />
                </div>
                <div className="products-field">
                    <span className="products-field__title"> Phone Number</span>
                    <SimpleInput
                        disabled={true}
                        name="phone_number"
                        value={formData.phone_number.value}
                        onChange={(e) =>
                            onInputChange(
                                'phone_number',
                                e.target.value,
                                setFormData,
                                formData
                            )
                        }
                    />
                </div>

                <div className="products-field">
                    <span className="products-field__title">
                        Shipping Address{' '}
                    </span>
                    <SimpleInput
                        disabled={true}
                        name="shipping_address"
                        value={formData.shipping_address.value}
                        onChange={(e) =>
                            onInputChange(
                                'shipping_address',
                                e.target.value,
                                setFormData,
                                formData
                            )
                        }
                        className="smallInput"
                    />
                </div>

                <div className="date">
                    <span className="products-field__title">
                        Requested Ship Date
                    </span>
                    <div className="date__wrap">
                        <div className="date__asap">
                            <div className="date__text">
                                As soon as possible
                            </div>
                            <CustomCheckbox
                                styleCheckbox="big"
                                checked={checkedASAP}
                                onChange={() => {
                                    setCheckedASAP((prev) => !prev)
                                    setChecked(false)
                                }}
                            />
                        </div>
                        <div className="date__asap">
                            <div className="date__text">
                                I want to specify when to receive it
                            </div>
                            <CustomCheckbox
                                styleCheckbox="big"
                                checked={checked}
                                onChange={() => {
                                    setChecked((prev) => !prev)
                                    setCheckedASAP(false)
                                }}
                            />
                        </div>

                        <DatePicker
                            minDate={new Date()}
                            maxDate={new Date().setDate(
                                new Date().getDate() + 9
                            )}
                            disabled={checked ? false : true}
                            dateFormat="yyyy-MM-dd"
                            name="ship_date"
                            selected={
                                checkedASAP ? '' : formData.ship_date.value
                            }
                            onChange={(data) =>
                                onInputChange(
                                    'ship_date',
                                    data,
                                    setFormData,
                                    formData
                                )
                            }
                            onBlur={(data) =>
                                onFocusOut(
                                    'ship_date',
                                    formData.ship_date.value,
                                    setFormData,
                                    formData
                                )
                            }
                            errorText={formData.ship_date.error}
                            className={`${
                                formData.ship_date.error ? 'red' : ''
                            } date-picker`}
                            onKeyDown={(e) => {
                                e.preventDefault()
                            }}
                        />
                    </div>
                </div>

                <div className="products-field">
                    <span className="products-field__title">Notes</span>
                    <textarea
                        className="products-field__text-area"
                        maxLength={255}
                        name="notes"
                        value={formData.notes.value}
                        onChange={(e) =>
                            onInputChange(
                                'notes',
                                e.target.value,
                                setFormData,
                                formData
                            )
                        }
                        onBlur={(e) =>
                            onFocusOut(
                                'notes',
                                e.target.value,
                                setFormData,
                                formData
                            )
                        }
                    />
                </div>
                <div className="products-field">
                    <span className="products-field__title"> PO#</span>
                    <SimpleInput
                        name="po"
                        value={formData.po.value}
                        maxLength={255}
                        onChange={(e) =>
                            onInputChange(
                                'po',
                                e.target.value,
                                setFormData,
                                formData
                            )
                        }
                        onBlur={(e) =>
                            onFocusOut(
                                'po',
                                e.target.value,
                                setFormData,
                                formData
                            )
                        }
                        errorText={formData.po.error}
                        className={`${formData.po.error ? 'red' : ''}`}
                    />
                </div>
            </div>
            <div className="products-details">
                <div className="products-details__chekbox">
                    <CustomCheckbox
                        checked={formData.isChecked}
                        label="I agree that I am placing an order on our account, in accordance to the Cowichan Souvenir order policies"
                        styleCheckbox="big"
                        onChange={handleCheckbox}
                    />
                </div>

                <CustomButton
                    onClick={() =>
                        formData.isChecked
                            ? goToConfirmation(formData)
                            : setErrorModal(true)
                    }
                    styleButton="default"
                >
                    Place Order
                </CustomButton>
            </div>
        </div>
    )
}

export default OrderDetails

import React from 'react'
import CustomButton from '../CustomButton/CustomButton'
import css from './Popup.module.css'

const Popup = ({ message, handleClose }) => {

    return (
        <div className={css.overlay}>
            <div className={css.wrapper}>
                <p className={css.message}>{message}</p>
                <CustomButton styleButton="white" onClick={handleClose}>
                    OK
                </CustomButton>
            </div>
        </div>
    )
}

export default Popup

import servise from './servise'

export const login = (data) => {
    return servise.post('auth/login', data)
}

export const logout = () => {
    return servise.post('auth/logout')
}

export const me = () => {
    return servise.get('auth/me')
}

export const resetPassword = (data) => {
    return servise.post('auth/reset-password', data)
}

export const forgotPassword = (email) => {
    return servise.post('auth/forgot-password', email)
}

export const getProducts = (page, searchValue) => {
    return servise.get(
        `products?page=${page}&per_page=10&search=${searchValue}`
    )
}

export const addToCart = (data) => {
    return servise.post(`carts?${data}`)
}

export const updateCart = (id, data) => {
    return servise.post(`carts/${id}?${data}`)
}

export const contactUs = (data) => {
    return servise.post('common/pages/contact-us', data)
}

export const getCart = (page, perPage) => {
    return servise.get(`carts?page=${page}&per_page=${perPage}`)
}

export const deleteFromCart = (id) => {
    return servise.delete(`carts/${id}`)
}

export const getCartsTotal = () => {
    return servise.get('carts/total')
}

export const getMe = () => {
    return servise.get('auth/me')
}

export const createOrder = (data) => {
    return servise.post('order', data)
}

export const changePassword = (data) => {
    return servise.post('auth/password-change', data)
}

export const getOrders = (page) => {
    return servise.get(`order?page=${page}&per_page=10`)
}

export const getOrder = (id) => {
    return servise.get(`order/${id}`)
}

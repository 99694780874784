import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setProducts, setQuantityError } from '../../helpers/redux/products'
import {
    addToCart,
    updateCart,
    deleteFromCart,
    getCart,
    getCartsTotal,
} from '../../helpers/crud'
import './product.scss'
import { setProductsInCart } from '../../helpers/redux/products'
import classNames from 'classnames'
import { SimpleInput } from '../CustomInput/SimpleInput'
import CustomButton from '../CustomButton/CustomButton'
import { ReactComponent as Image } from '../../assets/image.svg'
import { ReactComponent as Close } from '../../assets/close.svg'
import { Loader } from '../../components/Loader/Loader'
import { toast } from 'react-toastify'
import { setTotals } from '../../helpers/redux/products'

const MIN_QUANTITY = 12
const Product = ({
    type = false,
    product,
    orderViewPage,
    setThumbnail,
    setCart,
    cart,
    handleBack,
}) => {
    const { products, productsInCart } = useSelector((state) => state.products)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const handleInput = (id, size, e) => {
        if (isNaN(e.target.value)) return

        if (!type) {
            dispatch(
                setProducts(
                    products.map((prod) => {
                        if (prod.id === id) {
                            return {
                                ...prod,
                                sizes: prod.sizes.map((el) => {
                                    if (el.size === size) {
                                        return {
                                            ...el,
                                            amount: +e.target.value || 0,
                                        }
                                    }
                                    return el
                                }),
                            }
                        }
                        return prod
                    })
                )
            )
        } else {
            const cartArray = [...cart]
            const element = cartArray.find((elem) => elem.id === id)
            const index = cartArray.findIndex((elem) => elem.id === id)
            const res = {
                ...element,
                sizes: element.sizes.map((el) => {
                    if (el.size === size) {
                        return {
                            ...el,
                            amount: +e.target.value || 0,
                        }
                    }
                    return el
                }),
            }
            cartArray.splice(index, 1, res)
            setCart(cartArray)
        }
    }

    const handleSave = (id, e) => {
        if (
            +product.sizes.reduce((acc, el) => (acc = +acc + +el.amount), 0) <
            MIN_QUANTITY
        ) {
            dispatch(
                setQuantityError(
                    MIN_QUANTITY -
                    +product.sizes.reduce(
                        (acc, el) => (acc = +acc + +el.amount),
                        0
                    )
                )
            )
            return
        }

        const res = product.sizes
            .map((el) => `&amount_${el.size.toLowerCase()}=${el.amount}`)
            .join('')

        addToCart(`${res}&product_id=${id}`).then(() => {
            toast.success('The product has been updated successfully')
            getCartsTotal().then((res) => dispatch(setTotals(res.data)))
        })
    }

    const handleRemove = (id, e) => {
        setLoading(true)
        deleteFromCart(product.id)
            .then((res) => {
                getCart(1, 10).then((res) => {
                    if (res.data.data.length < 1) {
                        handleBack()
                    }
                    setCart(res.data.data)
                    getCartsTotal().then((res) => dispatch(setTotals(res.data)))
                    toast.success('The product was successfully deleted')
                })
            })
            .finally(() => setLoading(false))
    }

    return (
        <div className={classNames('product', { 'product--small': type })}>
            {loading && <Loader loading={loading} />}
            <div
                className={classNames({
                    'product-main': true,
                    'product-main--center': type,
                })}
            >
                {type && !orderViewPage && (
                    <div className="product__close">
                        <Close
                            className="product__svg_close"
                            onClick={handleRemove}
                        />
                    </div>
                )}
                <div className="product-picture">
                    {product.image_url ? (
                        <img
                            style={{
                                background: product?.shirt_color?.RGB
                                    ? product?.shirt_color?.RGB
                                    : product?.hat_color?.RGB
                                        ? product?.hat_color?.RGB
                                        : product?.pin_color?.RGB
                                            ? product?.pin_color?.RGB
                                            : 'white',
                            }}
                            className="product__img"
                            alt="product"
                            src={product.image_url}
                            onClick={() => setThumbnail(product.id)}
                        />
                    ) : (
                        <>
                            <div className="product-without_picture">
                                <p className="product__img_none">
                                    Image not Avaliable
                                </p>
                                <Image className="product__svg" />
                            </div>
                        </>
                    )}
                </div>
                <div className="product-info">
                    <ul className="product-ul">
                        <li className="product__desc product__title">
                            {product?.name}
                        </li>
                        <li className="product__desc">
                            Product Code:{' '}
                            <span
                                style={{ color: '#11c4ec', fontWeight: '600' }}
                            >
                                {product?.product_code}
                            </span>
                        </li>
                        {product?.shirt_color?.Color && <li className="product__desc">
                            Garment Colour:{' '}
                            <span
                                style={{ color: '#11c4ec', fontWeight: '600' }}
                            >
                                {product?.shirt_color?.Color}
                            </span>
                        </li>}
                        <li className="product__desc">
                            {product?.hat_color?.Color && (
                                <>
                                    Hat Colour:{' '}
                                    <span
                                        style={{
                                            color: '#11c4ec',
                                            fontWeight: '600',
                                        }}
                                    >
                                        {product?.hat_color?.Color}
                                    </span>
                                </>
                            )}
                        </li>
                    </ul>
                    {!type && <p>{product?.description}</p>}
                </div>
            </div>
            <div className="product-select">
                <div className="product-head">
                    <div className="product__head">
                        <div className="product__sub_title">
                            <p className="product-size">Avaliable Sizes:</p>
                            {product.sizes?.map((el) => (
                                <div className="product-selected" key={el.size}>
                                    {el.size}
                                </div>
                            ))}
                        </div>
                        <div className="product__sub_title product__sub_title--big">
                            <p className="product-size">Prices:</p>
                            {product.sizes?.map((el, index) => (
                                <div
                                    className="product-selected cost"
                                    key={el.size}
                                >
                                    {(+el.cost).toFixed(2)}
                                </div>
                            ))}
                        </div>
                        <div className="product__sub_title">
                            <p className="product__blue product-size">
                                Input Quantities:
                            </p>
                            {product.sizes?.map((el) => (
                                <div
                                    key={el.size}
                                    className="product-selected product-selected--padding"
                                >
                                    <SimpleInput
                                        style={
                                            el.cost === 0 || orderViewPage
                                                ? { cursor: 'context-menu' }
                                                : {}
                                        }
                                        disabled={
                                            el.cost === 0 || orderViewPage
                                                ? true
                                                : false
                                        }
                                        maxLength={4}
                                        classInput="small"
                                        className="qtyInput"
                                        placeholder={el.cost === 0 ? '--' : '0'}
                                        value={el.amount || ''}
                                        onChange={
                                            orderViewPage
                                                ? ''
                                                : handleInput.bind(
                                                    this,
                                                    product.id,
                                                    el.size
                                                )
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="product-total">
                    <p>
                        Total Quantity:&nbsp;&nbsp;&nbsp;
                        <span className="product__blue">
                            {product.sizes.reduce(
                                (acc, el) => (acc = +acc + +el.amount),
                                0
                            )}
                        </span>
                    </p>
                    <p>
                        Total Cost:&nbsp;&nbsp;&nbsp;
                        <span className="product__blue">
                            {product.sizes.reduce(
                                (acc, el) =>
                                    (acc =
                                        +acc + +el.cost * +el.amount).toFixed(
                                            2
                                        ),
                                0
                            )}
                        </span>
                    </p>
                </div>
                <div
                    className={classNames({
                        'product-footer': true,
                    })}
                >
                    {!type &&
                        +product.sizes.reduce(
                            (acc, el) => (acc = +acc + +el.amount),
                            0
                        ) < MIN_QUANTITY && (
                            <p className="product__status product__status--error">
                                {`*Minimum order quantity is ${MIN_QUANTITY}. Please add ${MIN_QUANTITY -
                                    product.sizes.reduce(
                                        (acc, el) => (acc = +acc + +el.amount),
                                        0
                                    )
                                    } more
                            items to save to cart.`}
                            </p>
                        )}
                    {!type &&
                        +product.sizes.reduce(
                            (acc, el) => (acc = +acc + +el.amount),
                            0
                        ) >= MIN_QUANTITY && (
                            <p className="product__status product__status--success">
                                *Minimum order quantity reached. Items can be
                                saved to cart.
                            </p>
                        )}
                    {productsInCart.find((el) => el.id === product.id) && (
                        <p className="product__saved">Saved</p>
                    )}
                    {orderViewPage ? (
                        ''
                    ) : (
                        <CustomButton
                            styleButton="white"
                            onClick={handleSave.bind(this, product.id)}
                        >
                            {type ? 'Save Changes' : 'Save to Cart'}
                        </CustomButton>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Product

import React, { useState, useReducer } from 'react'
import './emaillinkerror.scss'
import logo from '../../assets/cowichan.png'
import ForgotPopup from '../../components/Popup/ForgotPopup'
import { forgotPassword } from '../../helpers/crud'
import {
    onInputChange,
    onFocusOut,
    formsReducer,
    updateFormAction,
    validateForm,
    fillFormAction,
} from '../../helpers/formUtils'

export const EmailLinkError = () => {
    const initialState = {
        email: { value: '', touched: '', hasError: '', error: '' },
        isFormValid: false,
    }
    const [formData, setFormData] = useReducer(formsReducer, initialState)
    const handleForgot = () => setForgot(true)
    const [forgot, setForgot] = useState(false)
    const [sent, setSent] = useState(false)
    const [error, setError] = useState(false)
    const handleClose = () => {
        setForgot(false)
        setSent(false)
        setError(false)
    }

    const handleConfirm = (data, setData) => {
        const isValid = validateForm(data, setData)
        if (isValid) {
            forgotPassword({ email: data.email.value })
                .then((res) => {
                    setSent(true)
                })
                .catch((err) => {
                    setData(
                        fillFormAction({
                            ...data,
                            email: {
                                value: formData.email.value,
                                touched: true,
                                hasError: true,
                                error: err.response.data.message,
                            },
                        })
                    )
                })
        }
    }

    return (
        <div className="errorpage">
            {forgot && (
                <ForgotPopup onClose={handleClose} onConfirm={handleConfirm} />
            )}
            <img className="errorpage__logo" src={logo} alt="cowichan-logo" />
            <div className="errorpage__text">
                The link is no longer valid, please click on Forgot Password
                below to receive a new link.
            </div>
            <div className="errorpage__btns">
                <button className="errorpage__back" onClick={handleForgot}>
                    Back to Login Page
                </button>
                <button className="errorpage__forgot" onClick={handleForgot}>
                    Forgot Password?
                </button>
            </div>
        </div>
    )
}

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isAuth: null,
    user: {},
    profileInfo: []
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logIn: (state, action) => {
            state.isAuth = true
            state.user = action.payload
        },
        checkAuth: (state, action) => {
            state.isAuth = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        logOut: (state) => {
            state.isAuth = false
        },
        passwordRecoveryEmail: (state, action) => {
            state.email = action.payload
        },
        profileInfo: (state, action) => {
            state.profileInfo = action.payload
        },
    },
})

export const {
    logIn,
    checkAuth,
    logOut,
    setUser,
    passwordRecoveryEmail,
    profileInfo
} = authSlice.actions
export default authSlice.reducer

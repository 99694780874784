import React from 'react'
import logo from '../../assets/cowichan.png'
import './subHeader.scss'
import classNames from 'classnames'

const SubHeader = ({ isBig = false, isPay = false }) => {
    return (
        <div
            className={classNames({
                'sub-header': true,
                'sub-header--big': isBig,
                'sub-header--pay': isPay,
            })}
        >
            <img className="sub-header__img" src={logo} alt="cowichan" />
        </div>
    )
}

export default SubHeader

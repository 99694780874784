import { combineReducers } from '@reduxjs/toolkit'
import authReducer from './auth'
import productsReducer from './products'
import ordersReducer from './orders'

export const rootReducer = combineReducers({
    auth: authReducer,
    products: productsReducer,
    orders: ordersReducer
})

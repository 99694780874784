import React, { useState, useReducer } from 'react'
import './login.scss'
import logo from '../../assets/cowichan.png'
import { SimpleInput } from '../../components/CustomInput/SimpleInput'
import { login, forgotPassword } from '../../helpers/crud'
import { useDispatch } from 'react-redux'
import { logIn } from '../../helpers/redux/auth'
import { Loader } from '../../components/Loader/Loader'
import ForgotPopup from '../../components/Popup/ForgotPopup'
import iconEye from '../../assets/free-icon-eye-159604.png'
import closeEye from '../../assets/close-eye.png'
import {
    onInputChange,
    onFocusOut,
    formsReducer,
    validateForm,
    fillFormAction,
} from '../../helpers/formUtils'

const initialState = {
    email: { value: '', touched: '', hasError: '', error: '' },
    password: { value: '', touched: '', hasError: '', error: '' },
    isFormValid: false,
}

const Login = () => {
    const [loading, setLoading] = useState(false)
    const [forgot, setForgot] = useState(false)
    const [sent, setSent] = useState(false)
    const [error, setError] = useState(false)
    const [formData, setFormData] = useReducer(formsReducer, initialState)
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false)

    const loginRequest = () => {
        const isValid = validateForm(formData, setFormData)
        if (isValid) {
            setLoading(true)
            const data = {
                email: formData.email.value.trim(),
                password: formData.password.value.trim(),
            }
            login(data)
                .then((res) => {
                    dispatch(logIn(res.data.data))
                    localStorage.setItem('token', res.data.auth_token)
                })
                .catch((err) => {
                    setFormData(
                        fillFormAction({
                            ...formData,
                            email: {
                                value: formData.email.value,
                                touched: true,
                                hasError: true,
                                error: err.response.data.message,
                            },
                            password: {
                                value: formData.password.value,
                                touched: true,
                                hasError: true,
                                error: err.response.data.message,
                            },
                        })
                    )
                })
                .finally(() => setLoading(false))
        }
    }

    const handleForgot = () => setForgot(true)

    const handleClose = () => {
        setForgot(false)
        setSent(false)
        setError(false)
    }

    const handleConfirm = (data, setData) => {
        const isValid = validateForm(data, setData)
        if (isValid) {
            forgotPassword({ email: data.email.value })
                .then((res) => {
                    setSent(true)
                })
                .catch((err) => {
                    setData(
                        fillFormAction({
                            ...data,
                            email: {
                                value: formData.email.value,
                                touched: true,
                                hasError: true,
                                error: err.response.data.message,
                            },
                        })
                    )
                })
        }
    }

    return (
        <div className="login">
            {forgot && (
                <ForgotPopup
                    error={error}
                    setError={setError}
                    sent={sent}
                    onClose={handleClose}
                    onConfirm={handleConfirm}
                />
            )}
            <Loader loading={loading} />
            <div className="login-hero">
                <img className="login__logo" src={logo} alt="cowichan-logo" />
                <h3 className="login__title">Online Ordering Portal</h3>
                <div className="login__form">
                    <div className="login-wrapper">
                        <p className="login__label login--marginMin">Email</p>
                        <div className="df">
                            <SimpleInput
                                maxLength={100}
                                classInput="white"
                                className={formData.email.hasError ? 'red' : ''}
                                value={formData.email.value}
                                onChange={(e) =>
                                    onInputChange(
                                        'email',
                                        e.target.value,
                                        setFormData,
                                        formData
                                    )
                                }
                                onBlur={(e) =>
                                    onFocusOut(
                                        'email',
                                        e.target.value,
                                        setFormData,
                                        formData
                                    )
                                }
                                errorText={formData.email.error}
                            />
                        </div>
                    </div>
                    <div className="login-wrapper">
                        <div className="login-forget">
                            <p className="login__label login__label--margin">
                                Password
                            </p>
                            <p className="login__forgot" onClick={handleForgot}>
                                Forgot Password?
                            </p>
                        </div>
                        <div className="password-wrapper">
                            <div className="df">
                                <SimpleInput
                                    maxLength={255}
                                    classInput="white"
                                    className={`${
                                        formData.password.hasError ? 'red' : ''
                                    } password`}
                                    type={showPassword ? 'text' : 'password'}
                                    value={formData.password.value}
                                    onChange={(e) =>
                                        onInputChange(
                                            'password',
                                            e.target.value,
                                            setFormData,
                                            formData
                                        )
                                    }
                                    onBlur={(e) =>
                                        onFocusOut(
                                            'password',
                                            e.target.value,
                                            setFormData,
                                            formData
                                        )
                                    }
                                    errorText={formData.password.error}
                                />
                            </div>
                            {showPassword ? (
                                <img
                                    src={closeEye}
                                    className="eye"
                                    onClick={() =>
                                        setShowPassword((prev) => !prev)
                                    }
                                />
                            ) : (
                                <img
                                    src={iconEye}
                                    className="eye"
                                    onClick={() =>
                                        setShowPassword((prev) => !prev)
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <div className="login-btn">
                        <button
                            className="login__button"
                            onClick={() => loginRequest()}
                        >
                            Log In
                        </button>
                    </div>
                </div>
                <div className="login__adrees">
                    *If you do not have an account, please contact sales at
                    <a
                        href="mailto:webmaster@example.com"
                        className="login__link"
                    >
                        &nbsp;sales@cowichansouvenir.com{' '}
                    </a>
                    or call 1-800-939-3223
                </div>
            </div>
        </div>
    )
}

export default Login

import React, { useEffect } from 'react'
import './orderHistory.scss'
import classNames from 'classnames'
import { useNavigate } from 'react-router'
import { setOrderViewPage } from '../../../helpers/redux/products'
import { useDispatch } from 'react-redux'
import moment from 'moment/moment'

const OrderHistory = ({ elem }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <div className="order-history">
            <div className="order-history-row">
                <p>
                    <span
                        className="order-history__title"
                        onClick={() => {
                            localStorage.setItem('orderViewCow', elem.id)
                            dispatch(setOrderViewPage(elem.id))
                            navigate(`/order-view/${elem.id}`)
                        }}
                    >
                        Order {elem.number}
                    </span>
                    <span
                        className={classNames('order-history__progress',{
                            'order-history__progress--blue' : elem.status?.trim() === 'Received Order',
                            'order-history__progress--yellow' : elem.status?.trim() === 'Waiting on Inventory',
                            'order-history__progress--lblue' : elem.status?.trim() === 'In Production',
                            'order-history__progress--green' : elem.status?.trim() === 'Shipped',
                            'order-history__progress--red' : elem.status?.trim() === 'Error',
                        })}
                    >
                        {' '}
                        <span style={{ color: '#11C4EC' }}>-</span>{' '}
                        {elem.status}
                    </span>
                </p>
                <p className="order-history__total">
                    Total Cost:{' '}
                    <span className="order-history__total order-history__total--bold">
                        ${elem?.total_price}
                    </span>
                </p>
            </div>
            <div className="order-history-row">
                <div className="order-history-date">
                    <p className="order-history__date">
                        Date Ordered:{' '}
                        {elem.created_at
                            ? moment(elem.created_at).format('YYYY-MM-DD')
                            : 'N/A'}
                    </p>
                    <p className="order-history__date">
                        Date Shipped:{' '}
                        {elem?.shipped_at
                            ? moment(elem.shipped_at).format('YYYY-MM-DD')
                            : 'N/A'}
                    </p>
                </div>
                <p className="order-history__total">
                    Total Quantity:{' '}
                    <span className="order-history__total order-history__total--bold">
                        {elem?.total_count}
                    </span>
                </p>
            </div>
        </div>
    )
}

export default OrderHistory

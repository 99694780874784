import React, { useReducer } from 'react'
import CustomButton from '../CustomButton/CustomButton'
import { SimpleInput } from '../CustomInput/SimpleInput'
import css from './Popup.module.css'
import {
    onInputChange,
    onFocusOut,
    formsReducer,
    updateFormAction,
    validateForm,
    fillFormAction,
} from '../../helpers/formUtils'

const initialState = {
    email: { value: '', touched: '', hasError: '', error: '' },
    isFormValid: false,
}

const ForgotPopup = ({ error, sent, onClose, onConfirm }) => {
    const [formData, setFormData] = useReducer(formsReducer, initialState)

    return (
        <div className={css.overlay}>
            <div className={css.wrapper}>
                <p className={css.message}>
                    {sent
                        ? `Recovery email has been sent to ${formData.email.value}`
                        : 'Please provide your email address to reset your password'}
                </p>
                {!sent && (
                    <>
                        {error && (
                            <p className={css.error}>
                                Please provide valid email
                            </p>
                        )}
                        <SimpleInput
                            maxLength={100}
                            className={formData.email.hasError ? 'red' : ''}
                            value={formData.email.value || ''}
                            onChange={(e) =>
                                onInputChange(
                                    'email',
                                    e.target.value,
                                    setFormData,
                                    formData
                                )
                            }
                            onBlur={(e) =>
                                onFocusOut(
                                    'email',
                                    e.target.value,
                                    setFormData,
                                    formData
                                )
                            }
                            errorText={formData.email.error}
                        />
                    </>
                )}
                <div className={`${css.btns} ${sent ? css.jcc : ''}`}>
                    <CustomButton
                        styleButton={sent ? 'white' : 'cancel'}
                        onClick={onClose}
                    >
                        {sent ? 'OK' : 'I remember my password'}
                    </CustomButton>
                    {!sent && (
                        <CustomButton
                            styleButton="white"
                            onClick={onConfirm.bind(
                                this,
                                formData,
                                setFormData
                            )}
                        >
                            Send recovery email
                        </CustomButton>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ForgotPopup

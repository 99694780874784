import React from 'react'
import './input.scss'
import classNames from 'classnames'
import { ReactComponent as Search } from '../../assets/search.svg'

export const SimpleInput = ({
    name,
    value,
    placeholder,
    onChange,
    maxLength = 255,
    disabled = false,
    classInput,
    className,
    isSearch,
    onBlur,
    errorText,
    type,
    style,
}) => {
    return (
        <div
            className={classNames({
                'custom-input': true,
                'custom-input--white': classInput === 'white',
                'custom-input--search': classInput === 'search',
                'custom-input--small': classInput === 'small',
                'custom-input--red': classInput === 'red',
            })}
        >
            <input
                style={style}
                name={name}
                className={`custom-input__input ${className}`}
                type={type ? type : 'text'}
                placeholder={placeholder || ''}
                value={value || ''}
                onChange={onChange}
                maxLength={maxLength}
                disabled={disabled}
                onBlur={onBlur}
            />
            {isSearch && <Search className="custom-input__icon" />}
            {errorText && <div className="error-text">{errorText}</div>}
        </div>
    )
}

import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Popup from '../../../components/Popup/Popup'
import Header from '../../../components/Header/Header'
import SubHeader from '../../../components/SubHeader/SubHeader'
import HeaderInfo from '../../../components/HeaderInfo/HeaderInfo'
import {
    setOrderViewPage,
    setQuantityError,
} from '../../../helpers/redux/products'
import CustomButton from '../../../components/CustomButton/CustomButton'
import Product from '../../../components/Product/Product'
import { ReactComponent as GoBack } from '../../../assets/back.svg'
import { Loader } from '../../../components/Loader/Loader'
import { useEffect } from 'react'
import { getOrder, getCart } from '../../../helpers/crud'
import { useNavigate } from 'react-router'
import { useRef } from 'react'
import { setOrderList } from '../../../helpers/redux/orders'
import { ThambnailModal } from '../../../components/Popup/ThambnailModal'

const OrderSummary = ({
    handleDetailt,
    handleBack,
    modal,
    modalText,
    setModal,
    goToProducts,
}) => {
    const { quantityError, orderViewPage } = useSelector(
        (state) => state.products
    )
    const [cart, setCart] = useState([])
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [order, setOrder] = useState([])
    const [page, setPage] = useState(1)
    const [fetching, setFetching] = useState(true)
    const [lastPage, setLastPage] = useState(10)
    const totals = useSelector((state) => state.products.totals)
    const dispatch = useDispatch()
    const ref = useRef()
    const [thumbnail, setThumbnail] = useState(false)

    console.log('order', order)

    useEffect(() => {
        if (!location) return
        console.log(location.pathname.split('/')[2])
        if (location.pathname.split('/')[2]) {
            const id = location.pathname.split('/')[2]
            dispatch(setOrderViewPage(true))
            getOrder(id).then((res) => {
                return setOrder(res.data.data)
            })
        }
    }, [location])

    useEffect(() => {
        const orderNumber = localStorage.getItem('orderViewCow')
        if (orderNumber) {
            dispatch(setOrderViewPage(orderNumber))
        } else {
            dispatch(setOrderViewPage(false))
        }
    }, [])

    useEffect(() => {
        ref?.current?.addEventListener('scroll', scrollHandler)
        return () => ref?.current?.removeEventListener('scroll', scrollHandler)
    }, [ref])

    const scrollHandler = (e) => {
        if (
            e.target.scrollHeight -
                (e.target.scrollTop + ref.current.clientHeight) <
            100
        ) {
            setFetching(true)
        }
    }

    useEffect(() => {
        if (page > lastPage) return
        if (fetching) {
            setLoading(true)
            getCart(page, 10)
                .then((res) => {
                    setLastPage(res.data.meta.last_page)
                    setPage((prev) => prev + 1)
                    setCart(res.data.data)
                })
                .finally(() => {
                    setLoading(false)
                    setFetching(false)
                })
        }
    }, [fetching, lastPage])

    useEffect(() => {
        if (orderViewPage) {
            getOrder(orderViewPage).then((res) => setOrder(res.data.data))
        }
    }, [orderViewPage])

    const BackToOrderList = () => {
        dispatch(setOrderList([]))
        navigate('/history')
    }

    return (
        <div className="wrapper">
            {modal ? (
                <Popup
                    message={modalText}
                    handleClose={() => setModal(false)}
                />
            ) : (
                ''
            )}
            {thumbnail ? (
                <ThambnailModal id={thumbnail} setThumbnail={setThumbnail} />
            ) : (
                ''
            )}{' '}
            {loading && <Loader loading={loading} />}
            {quantityError && (
                <Popup
                    message={`Minimum quantity is not reached, please add ${quantityError} more items to save cart`}
                    handleClose={() => dispatch(setQuantityError(false))}
                />
            )}
            <Header goToProducts={goToProducts} />
            <SubHeader />
            <HeaderInfo title={orderViewPage ? 'Order View' : 'Order Summary'}>
                <div className="products-head">
                    <div>
                        <span>Cart Total: </span>
                        <span className="products-head__blue">
                            $
                            {orderViewPage
                                ? order?.total_price?.toFixed(2)
                                : totals?.total?.toFixed(2)}
                        </span>
                    </div>
                    <div>
                        <span>Total Units: </span>
                        <span className="products-head__blue">
                            {orderViewPage ? order.total_count : totals?.count}
                        </span>
                    </div>
                </div>
            </HeaderInfo>
            {
                <div className="products__search">
                    <div
                        className="products__back"
                        onClick={orderViewPage ? BackToOrderList : handleBack}
                    >
                        <GoBack />
                        {orderViewPage
                            ? 'Return to Orders'
                            : 'Return to Products'}
                    </div>
                </div>
            }
            <div className="products-hero" ref={ref}>
                {orderViewPage
                    ? order?.products?.map((prod) => (
                          <Product
                              key={prod.id}
                              product={prod}
                              type={true}
                              orderViewPage={orderViewPage}
                              setThumbnail={setThumbnail}
                          />
                      ))
                    : cart?.map((prod) => (
                          <Product
                              key={prod.id}
                              product={prod}
                              type={true}
                              orderViewPage={orderViewPage}
                              setCart={setCart}
                              cart={cart}
                              setThumbnail={setThumbnail}
                              handleBack={handleBack}
                          />
                      ))}

                <div className="products-summary">
                    <div className="products-summary__head">
                        <div className="products-summary__text">
                            <span className="products-summary__info">
                                Subtotal:
                            </span>
                            <span className="products-summary__info">
                                $
                                {orderViewPage
                                    ? order?.subtotal_price?.toFixed(2)
                                    : totals?.total?.toFixed(2)}
                            </span>
                        </div>
                        <div className="products-summary__text">
                            <span className="products-summary__info">
                                Shipping:
                            </span>
                            <span className="products-summary__info">
                                {orderViewPage
                                    ? order?.shipping_cost?.toFixed(2)
                                        ? `$${order?.shipping_cost?.toFixed(2)}`
                                        : 'To Be Determined'
                                    : 'To Be Determined'}
                            </span>
                        </div>
                        <div className="products-summary__text">
                            <span className="products-summary__info">
                                Total:
                            </span>
                            <span className="products-summary__info product__blue">
                                $
                                {orderViewPage
                                    ? order?.total_price?.toFixed(2)
                                    : totals?.total?.toFixed(2)}
                            </span>
                        </div>
                    </div>
                    <div className="products-summary__footer">
                        <p className="products__red">
                            *All orders are subjected to such delays based on
                            blanks avaliability and inventory shortages. Any
                            issues we will contact you as soon as we can.
                        </p>
                        {orderViewPage ? (
                            ''
                        ) : (
                            <CustomButton
                                onClick={handleDetailt}
                                styleButton="small"
                            >
                                Proceed to Checkout
                            </CustomButton>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderSummary

import React, { useState, useReducer } from 'react'
import { SimpleInput } from '../../components/CustomInput/SimpleInput'
import { resetPassword } from '../../helpers/crud'
import iconEye from '../../assets/free-icon-eye-159604.png'
import closeEye from '../../assets/close-eye.png'
import '../Login/login.scss'
import { Loader } from '../../components/Loader/Loader'
import {
    onInputChange,
    onFocusOut,
    formsReducer,
    validateForm,
    fillFormAction,
} from '../../helpers/formUtils'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { logIn } from '../../helpers/redux/auth'
import logo from '../../assets/cowichan.png'
import { useEffect } from 'react'

const initialState = {
    password: { value: '', touched: '', hasError: '', error: '' },
    confirm: { value: '', touched: '', hasError: '', error: '' },
    isFormValid: false,
}

const ResetPasswordPage = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const [formData, setFormData] = useReducer(formsReducer, initialState)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const validateConfirmPassword = (value) => {
        onFocusOut('confirm', value, setFormData, formData)
        if (formData.password.value !== value) {
            setFormData(
                fillFormAction({
                    ...formData,
                    confirm: {
                        value: value,
                        touched: true,
                        hasError: true,
                        error: 'Passwords must be the same',
                    },
                })
            )
        }
    }

    useEffect(() => {
        const email = location?.search?.split('&')[1]?.split('=')[1]
        const token = location?.search?.split('&')[0]?.split('=')[1]
        if (!email || !token) {
            navigate('/error-page')
        }
    }, [location])

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault()
        const isValid = validateForm(formData, setFormData)
        validateConfirmPassword(formData.confirm.value)
        if (isValid && !formData.confirm.error) {
            const data = {
                password: formData.password.value,
                password_confirmation: formData.confirm.value,
                email: location?.search?.split('&')[1].split('=')[1],
                token: location?.search?.split('&')[0].split('=')[1],
            }
            if (!data.email || !data.token) {
                navigate('error-page')
            }
            resetPassword(data)
                .then((res) => {
                    dispatch(logIn(res.data))
                    localStorage.setItem('token', res.data.auth_token)
                    toast.success(
                        'Thank you for updating your password, you can now login to the system with your new password'
                    )
                    navigate('/login')
                })
                .finally(() => setLoading(false))
        }
        setLoading(false)
    }

    return (
        <div className="login">
            <Loader loading={loading} />
            <div className="login-hero">
                <img className="login__logo" src={logo} alt="cowichan-logo" />
                <h3 className="login__title">Online Ordering Portal</h3>
                <div className="login__form">
                    <div className="login-wrapper">
                        <p className="login__label login--marginMin">
                            New Password
                        </p>
                        <div className="df">
                            <SimpleInput
                                maxLength={255}
                                type={showPassword ? 'text' : 'password'}
                                value={formData.password.value}
                                name="newPassword"
                                classInput="white"
                                onChange={(e) =>
                                    onInputChange(
                                        'password',
                                        e.target.value,
                                        setFormData,
                                        formData
                                    )
                                }
                                onBlur={(e) =>
                                    onFocusOut(
                                        'password',
                                        e.target.value,
                                        setFormData,
                                        formData
                                    )
                                }
                                errorText={formData.password.error}
                                className={`${
                                    formData.password.error ? 'red' : ''
                                } password`}
                            />
                            {showPassword ? (
                                <img
                                    src={closeEye}
                                    className="eye"
                                    onClick={() =>
                                        setShowPassword((prev) => !prev)
                                    }
                                    style={{ top: '10px' }}
                                />
                            ) : (
                                <img
                                    src={iconEye}
                                    className="eye"
                                    onClick={() =>
                                        setShowPassword((prev) => !prev)
                                    }
                                    style={{ top: '10px' }}
                                />
                            )}
                        </div>
                    </div>
                    <div className="login-wrapper">
                        <p className="login__label login--marginMin">
                            Confirm Password
                        </p>
                        <div className="df">
                            <SimpleInput
                                maxLength={255}
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={formData.confirm.value}
                                name="confirmPassword"
                                classInput="white"
                                onChange={(e) =>
                                    onInputChange(
                                        'confirm',
                                        e.target.value,
                                        setFormData,
                                        formData
                                    )
                                }
                                onBlur={(e) =>
                                    validateConfirmPassword(e.target.value)
                                }
                                errorText={formData.confirm.error}
                                className={`${
                                    formData.confirm.error ? 'red' : ''
                                } password`}
                            />
                            {showConfirmPassword ? (
                                <img
                                    src={closeEye}
                                    className="eye"
                                    onClick={() =>
                                        setShowConfirmPassword((prev) => !prev)
                                    }
                                    style={{ top: '10px' }}
                                />
                            ) : (
                                <img
                                    src={iconEye}
                                    className="eye"
                                    onClick={() =>
                                        setShowConfirmPassword((prev) => !prev)
                                    }
                                    style={{ top: '10px' }}
                                />
                            )}
                        </div>
                    </div>
                    <div className="login-btn">
                        <button
                            className="login__button"
                            onClick={handleSubmit}
                        >
                            Save
                        </button>
                    </div>
                </div>
                <div className="login__adrees">
                    *If you do not have an account, please contact sales at
                    <a
                        href="mailto:webmaster@example.com"
                        className="login__link"
                    >
                        &nbsp;sales@cowichansouvenir.com{' '}
                    </a>
                    or call 1-800-939-3223
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordPage

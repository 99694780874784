import React, { useState, useEffect } from 'react'
import AllProducts from './ProductPage/AllProducts'
import './products.scss'
import OrderSummary from './ProductPage/OrderSummary'
import OrderDetails from './ProductPage/OrderDetails'
import OrderConfirmation from './ProductPage/OrderConfirmation'
import { getCartsTotal, getCart } from '../../helpers/crud'
import { useDispatch, useSelector } from 'react-redux'
import { setTotals } from '../../helpers/redux/products'
import { setOrderViewPage } from '../../helpers/redux/products'
import { setProductsInCart } from '../../helpers/redux/products'

const Products = ({ setProductScreen, productScreen, goToProducts }) => {
    // products,summary,details,confirmation
    const { productsInCart } = useSelector((state) => state.products)
    const dispatch = useDispatch()
    const [modal, setIsModal] = useState(false)
    const [modalText, setIsModalText] = useState('')
    const totals = useSelector((state) => state.products.totals)

    const goToSummary = () => {
        dispatch(setOrderViewPage(false))
        localStorage.removeItem('orderViewCow')
        if (totals.count < 36) {
            setIsModal(true)
            setIsModalText(
                'Please note, 36 units are the minimum order quantity, please adjust the order to meet the minimums'
            )
        } else {
            setProductScreen('summary')
        }
    }
    const goToDetailt = () => {
        if (totals.count < 36) {
            setIsModal(true)
            setIsModalText(
                'Please note, 36 units are the minimum order quantity, please adjust the order to meet the minimums'
            )
        } else {
            setProductScreen('details')
        }
    }

    useEffect(() => {
        getCartsTotal().then((res) => dispatch(setTotals(res.data)))
    }, [productsInCart])

    const renderScreen = () => {
        switch (productScreen) {
            case 'products':
                return (
                    <AllProducts
                        hableHead={goToSummary}
                        modal={modal}
                        modalText={modalText}
                        setModal={setIsModal}
                        goToProducts={goToProducts}
                    />
                )
            case 'summary':
                return (
                    <OrderSummary
                        handleDetailt={goToDetailt}
                        handleBack={goToProducts}
                        modal={modal}
                        modalText={modalText}
                        setModal={setIsModal}
                        goToProducts={goToProducts}
                    />
                )
            case 'details':
                return (
                    <OrderDetails
                        setProductScreen={setProductScreen}
                        handleBack={goToSummary}
                        goToProducts={goToProducts}
                    />
                )
            case 'confirmation':
                return (
                    <OrderConfirmation
                        type="order"
                        goToProducts={goToProducts}
                    />
                )
            default:
                return <></>
        }
    }
    return <>{renderScreen()}</>
}

export default Products

import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_URL

const service = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: 'application/json',
        Connection: 'keep-alive',
    },
})

service.interceptors.request.use(
    (request) => {
        request.headers.Authorization = `Bearer ${localStorage.getItem(
            'token'
        )}`
        return request
    },
    (error) => {
        return error
    }
)

service.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response?.status) {
            const { status } = error.response

            if (status === 401 && window.location.pathname !== '/login') {
                localStorage.removeItem('token')
            }
        }

        return Promise.reject(error)
    }
)

export default service

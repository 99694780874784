import React from 'react'
import './button.scss'
import classNames from 'classnames'

const CustomButton = ({ children, onClick, styleButton, ...props }) => {
    return (
        <button
            {...props}
            onClick={onClick}
            className={classNames({
                'castom-button': true,
                'castom-button--small': styleButton === 'small',
                'castom-button--white': styleButton === 'white',
                'castom-button--default': styleButton === 'default',
                'castom-button--big': styleButton === 'big',
                'castom-button--cancel': styleButton === 'cancel',
            })}
        >
            {children}
        </button>
    )
}

export default CustomButton

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { ReactComponent as User } from '../../assets/user.svg'
import { logout } from '../../helpers/crud'
import { logOut } from '../../helpers/redux/auth'
import { Loader } from '../Loader/Loader'
import { setOrderList } from '../../helpers/redux/orders'
import './header.scss'

const Header = ({ goToProducts }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const user = useSelector((state) => state.auth.profileInfo)

    const logOutFunc = () => {
        setLoading(true)
        logout()
            .then((res) => {
                localStorage.removeItem('token')
                dispatch(logOut())
                navigate('/login')
            })
            .finally(() => setLoading(false))
    }

    return (
        <header className="header">
            {loading && <Loader loading={loading} />}
            <div className="header__info">
                <User />
                <p>
                    Welcome <span className="header-bold">{user?.Name}</span>
                </p>
            </div>
            <nav>
                <ul className="header-nav">
                    <li className="header__li" onClick={() => goToProducts()}>
                        <NavLink
                            to="/products"
                            className={({ isActive }) =>
                                isActive
                                    ? 'header__link header__link--active'
                                    : 'header__link'
                            }
                        >
                            Products
                        </NavLink>
                    </li>
                    <li className="header__li">
                        <NavLink
                            to="/change-password"
                            className={({ isActive }) =>
                                isActive
                                    ? 'header__link header__link--active'
                                    : 'header__link'
                            }
                        >
                            My Account
                        </NavLink>
                    </li>
                    <li className="header__li">
                        <NavLink
                            onClick={() => dispatch(setOrderList([]))}
                            to="/history"
                            className={({ isActive }) =>
                                isActive
                                    ? 'header__link header__link--active'
                                    : 'header__link'
                            }
                        >
                            Orders
                        </NavLink>
                    </li>
                    <li className="header__li">
                        <NavLink
                            to="/contact"
                            className={({ isActive }) =>
                                isActive
                                    ? 'header__link header__link--active'
                                    : 'header__link'
                            }
                        >
                            Contact Us
                        </NavLink>
                    </li>

                    <li className="header__li">
                        <div className="header__link" onClick={logOutFunc}>
                            Log Out
                        </div>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default Header

import React from 'react'
import { useSelector } from 'react-redux'
import css from './Popup.module.css'

export const ThambnailModal = ({ id, setThumbnail }) => {
    const products = useSelector((state) => state.products.products)
    const element = products?.find((elem) => elem.id === id)

    return (
        <div className={css.overlay}>
            <div className={css.wrapperImage}>
                <img
                    className={css.image}
                    src={element.image_url}
                    style={{
                        background: element?.shirt_color?.RGB
                            ? element?.shirt_color?.RGB
                            : element?.hat_color?.RGB
                            ? element?.hat_color?.RGB
                            : element?.pin_color?.RGB
                            ? element?.pin_color?.RGB
                            : 'white',
                    }}
                />
                <div
                    className={css.closeBtn}
                    onClick={() => setThumbnail(false)}
                >
                    &#10006;
                </div>
            </div>
        </div>
    )
}

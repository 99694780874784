import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    products: [],
    productsInCart: [],
    quantityError: false,
    orderViewPage: false,
    totals: '',
}

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload
        },
        setProductsInCart: (state, action) => {
            state.productsInCart = action.payload
        },
        setQuantityError: (state, action) => {
            state.quantityError = action.payload
        },
        setOrderViewPage: (state, action) => {
            state.orderViewPage = action.payload
        },
        setTotals: (state, action) => {
            state.totals = action.payload
        },
    },
})

export const {
    setProducts,
    setProductsInCart,
    setQuantityError,
    setOrderViewPage,
    setTotals,
} = productsSlice.actions
export default productsSlice.reducer

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    orderList: [],
    orderNumber: ''
}

export const orderSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setOrderList: (state, action) => {
            state.orderList = action.payload
        },
        setOrderNumber: (state, action) => {
           state.orderNumber = action.payload
       }
    },
})

export const {
    setOrderList,setOrderNumber
} = orderSlice.actions
export default orderSlice.reducer

import React, { useState, useReducer, useEffect } from 'react'
import Header from '../../components/Header/Header'
import SubHeader from '../../components/SubHeader/SubHeader'
import HeaderInfo from '../../components/HeaderInfo/HeaderInfo'
import { SimpleInput } from '../../components/CustomInput/SimpleInput'
import CustomTextArea from '../../components/CustomTextArea/CustomTextArea'
import { changePassword } from '../../helpers/crud'
import iconEye from '../../assets/free-icon-eye-159604.png'
import closeEye from '../../assets/close-eye.png'

import CustomButton from '../../components/CustomButton/CustomButton'
import './ChangePasswordPage.scss'
import {
    onInputChange,
    onFocusOut,
    formsReducer,
    updateFormAction,
    validateForm,
    fillFormAction,
} from '../../helpers/formUtils'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

const initialState = {
    password: { value: '', touched: '', hasError: '', error: '' },
    confirm: { value: '', touched: '', hasError: '', error: '' },
    CurrentPassword: { value: '', touched: '', hasError: '', error: '' },
    billingAddress: { value: '', touched: '', hasError: '', error: '' },
    shippingAddress: { value: '', touched: '', hasError: '', error: '' },
    isFormValid: false,
}

const ChangePasswordPage = () => {
    const [formData, setFormData] = useReducer(formsReducer, initialState)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [showCurrentPassword, setShowCurrentPassword] = useState(false)
    const user = useSelector((state) => state.auth.profileInfo)

    const validateConfirmPassword = (value) => {
        if (formData.password.value !== value) {
            setFormData(
                fillFormAction({
                    ...formData,
                    confirm: {
                        value: value,
                        touched: true,
                        hasError: true,
                        error: 'Passwords must be the same',
                    },
                })
            )
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const isValid = validateForm(formData, setFormData)
        validateConfirmPassword(formData.confirm.value)
        if (isValid && !formData.confirm.error) {
            const data = {
                password: formData.password.value,
                password_confirmation: formData.confirm.value,
                current_password: formData.CurrentPassword.value,
            }
            changePassword(data)
                .then((res) => {
                    toast.success('Your password was successfully changed!')
                })
                .catch((err) =>
                    setFormData(
                        fillFormAction({
                            ...formData,
                            CurrentPassword: {
                                value: formData.CurrentPassword.value,
                                touched: true,
                                hasError: true,
                                error: err.response.data[0],
                            },
                        })
                    )
                )
        }
    }

    useEffect(() => {
        if (!user) return
        setFormData(
            fillFormAction({
                ...formData,
                shippingAddress: {
                    value:
                        user?.shipping_address?.['Is Default Address'] === 1 && `${user?.shipping_address?.['Address Line 2'] ? user?.shipping_address?.['Address Line 2'] : ''} ${user?.shipping_address?.City ? user?.shipping_address?.City : ''} ${user?.shipping_address?.['Postal Code'] ? user?.shipping_address?.['Postal Code'] : ''} ${user?.shipping_address?.Country ? user?.shipping_address?.Country : ''}`,
                    touched: '',
                    hasError: '',
                    error: '',
                },
                billingAddress: {
                    value:
                        user?.billing_address?.['Is Default Address'] === 1 && `${user?.billing_address?.['Address Line 2'] ? user?.billing_address?.['Address Line 2'] : ''} ${user?.billing_address?.City ? user?.billing_address?.City : ''} ${user?.billing_address?.['Postal Code'] ? user?.billing_address?.['Postal Code'] : ''} ${user?.billing_address?.Country ? user?.billing_address?.Country : ''}`,
                    touched: '',
                    hasError: '',
                    error: '',
                },
            })
        )
    }, [user])

    return (
        <div className="wrapper changePassword">
            <Header />
            <SubHeader />
            <HeaderInfo title="My Account" />
            <form className="changePassword-form" onSubmit={handleSubmit}>
                <div className="changePassword-field">
                    <span className="changePassword-field__title">
                        Billing Address
                    </span>
                    <div className="df">
                        <SimpleInput
                            disabled={true}
                            value={formData.billingAddress.value}
                            name="billingAddress"
                            onChange={(e) =>
                                onInputChange(
                                    'billingAddress',
                                    e.target.value,
                                    setFormData,
                                    formData
                                )
                            }
                            onBlur={(e) =>
                                onFocusOut(
                                    'billingAddress',
                                    e.target.value,
                                    setFormData,
                                    formData
                                )
                            }
                            className="smallInput"
                        />
                    </div>
                </div>
                <div className="changePassword-field">
                    <span className="changePassword-field__title">
                        Shipping Address
                    </span>
                    <div className="df">
                        <SimpleInput
                            disabled={true}
                            value={formData.shippingAddress.value}
                            name="shippingAddress"
                            onChange={(e) =>
                                onInputChange(
                                    'shippingAddress',
                                    e.target.value,
                                    setFormData,
                                    formData
                                )
                            }
                            onBlur={(e) =>
                                onFocusOut(
                                    'shippingAddress',
                                    e.target.value,
                                    setFormData,
                                    formData
                                )
                            }
                            className="smallInput"
                        />
                    </div>
                </div>
                <div className="changePassword-field">
                    <span className="changePassword-field__title">
                        Current Password
                    </span>
                    <div className="df">
                        <SimpleInput
                            type={showCurrentPassword ? 'text' : 'password'}
                            value={formData.CurrentPassword.value}
                            name="CurrentPassword"
                            onChange={(e) =>
                                onInputChange(
                                    'CurrentPassword',
                                    e.target.value,
                                    setFormData,
                                    formData
                                )
                            }
                            onBlur={(e) =>
                                onFocusOut(
                                    'CurrentPassword',
                                    e.target.value,
                                    setFormData,
                                    formData
                                )
                            }
                            className={`${formData.CurrentPassword.error ? 'red' : ''
                                } smallInput`}
                            errorText={formData.CurrentPassword.error}
                        />
                        {!showCurrentPassword ? (
                            <img
                                src={iconEye}
                                className="eye"
                                onClick={() =>
                                    setShowCurrentPassword((prev) => !prev)
                                }
                            />
                        ) : (
                            <img
                                src={closeEye}
                                className="eye"
                                onClick={() =>
                                    setShowCurrentPassword((prev) => !prev)
                                }
                            />
                        )}
                    </div>
                </div>
                <div className="changePassword-field">
                    <span className="changePassword-field__title">
                        New Password
                    </span>
                    <div className="df">
                        <SimpleInput
                            type={showPassword ? 'text' : 'password'}
                            value={formData.password.value}
                            name="newPassword"
                            onChange={(e) =>
                                onInputChange(
                                    'password',
                                    e.target.value,
                                    setFormData,
                                    formData
                                )
                            }
                            onBlur={(e) =>
                                onFocusOut(
                                    'password',
                                    e.target.value,
                                    setFormData,
                                    formData
                                )
                            }
                            errorText={formData.password.error}
                            className={`${formData.password.error ? 'red' : ''
                                } smallInput`}
                        />
                        {!showPassword ? (
                            <img
                                src={iconEye}
                                className="eye"
                                onClick={() => setShowPassword((prev) => !prev)}
                            />
                        ) : (
                            <img
                                src={closeEye}
                                className="eye"
                                onClick={() => setShowPassword((prev) => !prev)}
                            />
                        )}
                    </div>
                </div>
                <div className="changePassword-field">
                    <span className="changePassword-field__title">
                        Confirm Password
                    </span>
                    <div className="df">
                        <SimpleInput
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={formData.confirm.value}
                            name="confirmPassword"
                            onChange={(e) =>
                                onInputChange(
                                    'confirm',
                                    e.target.value,
                                    setFormData,
                                    formData
                                )
                            }
                            onBlur={(e) =>
                                validateConfirmPassword(e.target.value)
                            }
                            errorText={formData.confirm.error}
                            className={`${formData.confirm.error ? 'red' : ''
                                } smallInput`}
                        />
                        {!showConfirmPassword ? (
                            <img
                                src={iconEye}
                                className="eye"
                                onClick={() =>
                                    setShowConfirmPassword((prev) => !prev)
                                }
                            />
                        ) : (
                            <img
                                src={closeEye}
                                className="eye"
                                onClick={() =>
                                    setShowConfirmPassword((prev) => !prev)
                                }
                            />
                        )}
                    </div>
                </div>

                <div className="changePassword-button">
                    <CustomButton styleButton="big" type="submit">
                        Save
                    </CustomButton>
                </div>
            </form>
        </div>
    )
}

export default ChangePasswordPage

import React from 'react'
import './headerInfo.scss'

const HeaderInfo = ({ children, title }) => {
    return (
        <div className="header-info">
            <h4 className="header-info__title">{title}</h4>
            <>{children}</>
        </div>
    )
}

export default HeaderInfo

import './App.css'
import { useEffect, useState } from 'react'
import Login from './pages/Login/Login'
import Products from './pages/Products/Products'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Contact from './pages/Contact/Contact'
import History from './pages/History/History'
import { useDispatch, useSelector } from 'react-redux'
import { checkAuth } from './helpers/redux/auth'
import { getMe } from './helpers/crud'
import { profileInfo } from './helpers/redux/auth'
import ChangePasswordPage from './pages/ChangePasswordPage/ChangePasswordPage'
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage'
import OrderSummary from './pages/Products/ProductPage/OrderSummary'
import { EmailLinkError } from './pages/EmailLinkError/EmailLinkError'

function App() {
    const isAuth = useSelector((state) => state.auth.isAuth)
    const dispatch = useDispatch()
    const [productScreen, setProductScreen] = useState('products')
    const location = useLocation()

    const goToProducts = () => {
        setProductScreen('products')
    }

    useEffect(() => {
        if (location.pathname !== '/products') {
            goToProducts()
        }
    }, [location])

    useEffect(() => {
        dispatch(checkAuth(!!localStorage.getItem('token')))
    }, [])

    useEffect(() => {
        if (isAuth) {
            getMe().then((res) => {
                dispatch(profileInfo(res.data))
            })
        }
    }, [isAuth])

    if (isAuth === null) return <></>

    return isAuth ? (
        <Routes>
            <Route path="/login" element={<Navigate to="/products" />} />
            <Route path="/*" element={<Navigate to="/products" />} />
            <Route
                path="/products"
                element={
                    <Products
                        setProductScreen={setProductScreen}
                        goToProducts={goToProducts}
                        productScreen={productScreen}
                    />
                }
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/history" element={<History />} />
            <Route path="/order-view/:id" element={<OrderSummary />} />
            <Route path="/change-password" element={<ChangePasswordPage />} />
        </Routes>
    ) : (
        <Routes>
            <Route path="/reset-password*" element={<ResetPasswordPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/*" element={<Navigate to="/login" />} />
            <Route path="/error-page" element={<EmailLinkError />} />
        </Routes>
    )
}

export default App
